import React from 'react';
import './Download.css';
import download from './prev.png';
import appStoreBadge from './app-store-badge.svg';

const Download = () => {
  return (
    <div className="download-container">
      <div className="download-hero">
        <h1>Checkmate: Routine Planner</h1>
        <p className="tagline">Master Your Daily Routine with Precision</p>
        
        <div className="app-store-section">
          <a 
            // href="https://apps.apple.com/app/checkmate-routine-planner" 
            href="https://www.dannyzachary.com/#/checkmate/coming-soon" 
            target="_blank" 
            rel="noopener noreferrer"
            className="app-store-button"
          >
            <img 
              src={appStoreBadge}
              alt="Download on the App Store"
              className="app-store-badge"
            />
          </a>
        </div>
      </div>

      <div className="features-section">
        <div className="feature">
          <h3>Smart Routine Planning</h3>
          <p>Create and optimize your daily routines with AI-powered suggestions</p>
        </div>
        <div className="feature">
          <h3>Progress Tracking</h3>
          <p>Monitor your habits and celebrate your achievements</p>
        </div>
        <div className="feature">
          <h3>Seamless Integration</h3>
          <p>Sync with your calendar and favorite productivity apps</p>
        </div>
      </div>

      <div className="cta-section">
        <h2>Start Your Journey to Better Routines Today</h2>
        <p>Join thousands of users who have transformed their daily lives with Checkmate</p>
      </div>

      <footer className="download-footer">
        <a 
          href="https://www.dannyzachary.com/#/checkmate/privacy"
          target="_blank"
          rel="noopener noreferrer"
          className="privacy-link"
        >
          Privacy Policy
        </a>
      </footer>
    </div>
  );
};

export default Download;