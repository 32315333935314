import React, { useState } from 'react';
import './ComingSoon.css';
import preview from './prev.png';
import appStoreBadge from './app-store-badge.svg';

const ComingSoon = () => {
  const [email, setEmail] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // TODO: Implement newsletter signup
    setIsSubscribed(true);
    setEmail('');
  };

  return (
    <div className="coming-soon-container">
      <div className="hero-section">
        <h1>Checkmate: Routine Planner</h1>
        <div className="tagline">Your Life's Next Power Move</div>
        <p className="launch-date">Launching Summer 2025</p>
        
        <div className="app-preview">
          <div className="phone-mockup">
            <img src={preview} alt="Checkmate App Preview" className="preview-image" />
          </div>
        </div>
      </div>

      <div className="features-grid">
        <div className="feature-card">
          <div className="feature-icon">🎯</div>
          <h3>AI-Powered Planning</h3>
          <p>Smart suggestions that adapt to your lifestyle and goals</p>
        </div>
        <div className="feature-card">
          <div className="feature-icon">📊</div>
          <h3>Visual Progress</h3>
          <p>Beautiful insights into your daily achievements</p>
        </div>
        <div className="feature-card">
          <div className="feature-icon">🔄</div>
          <h3>Seamless Sync</h3>
          <p>Works perfectly with your favorite calendar apps</p>
        </div>
        <div className="feature-card">
          <div className="feature-icon">🎮</div>
          <h3>Gamified Habits</h3>
          <p>Turn your routines into rewarding challenges</p>
        </div>
      </div>

      <div className="newsletter-section">
        <h2>Be First in Line</h2>
        <p>Get early access and exclusive launch offers</p>
        
        {!isSubscribed ? (
          <form onSubmit={handleSubmit} className="signup-form">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
            />
            <button type="submit">Notify Me</button>
          </form>
        ) : (
          <div className="success-message">
            <p>🎉 Thanks for joining! We'll keep you posted.</p>
            <div className="app-store-preorder">
              <p>Pre-order now on the App Store:</p>
              <a 
                href="https://apps.apple.com/app/checkmate-routine-planner" 
                target="_blank" 
                rel="noopener noreferrer"
                className="app-store-button"
              >
                <img 
                  src={appStoreBadge}
                  alt="Download on the App Store"
                  className="app-store-badge"
                />
              </a>
            </div>
          </div>
        )}
      </div>

      <div className="social-proof">
        <div className="countdown">
          <div className="count-item">
            <span className="number">2,547</span>
            <span className="label">Waitlist Signups</span>
          </div>
          <div className="count-item">
            <span className="number">14</span>
            <span className="label">Days to Launch</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;