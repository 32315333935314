import React, { useEffect, useState } from 'react'
import Map from './Map';
import BuyASign from './BuyASign';

const MainContentContainer = (props) => {
    const {
        contentType,
        setContentType,
        toggleSwitch,
    } = props;

    return (
        <div className="mainContentContainer">
            {
                contentType === "map" ? (
                    <Map
                        toggleSwitch={toggleSwitch}
                    />
                ) : contentType === "sign" ? (
                    <BuyASign />
                ) : null
            }
        </div>
    )
}

export default MainContentContainer