import React, { useEffect, useState } from "react";
import Bio from "./Bio";
import mixpanel from "mixpanel-browser";
import MainContentContainer from "./MainContentContainer";

mixpanel.init("a9271835960df6aadf4b4e5ba121bbd0", { ignore_dnt: true });

const Home = () => {
  const [contentType, setContentType] = useState("map");
  const [toggleSwitch, setToggleSwitch] = useState(true);

  useEffect(() => {
    mixpanel.track("DZ web page loaded");
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const queryString = window.location.href || "";

    if (queryString.includes("sign")) {
      // setContentType("sign");
    }
  }, [window.location.href]);

  return (
    <div
      className="bigHomeDiv"
      style={
        toggleSwitch
          ? { backgroundColor: "#fff" }
          : { backgroundColor: "#1e1e1e" }
      }
    >
      {/* <label className="switch">
        <input type="checkbox"
          checked={toggleSwitch}
        />
        <div
          className="button"
          onClick={() => setToggleSwitch(!toggleSwitch)}
        >
          <div className="light"></div>
          <div className="dots"></div>
          <div className="characters"></div>
          <div className="shine"></div>
          <div className="shadow"></div>
        </div>
      </label> */}
      <Bio
        contentType={contentType}
        setContentType={setContentType}
        toggleSwitch={toggleSwitch}
        setToggleSwitch={setToggleSwitch}
      />
      <MainContentContainer
        toggleSwitch={toggleSwitch}
        contentType={contentType}
        setContentType={setContentType}
      />
      <div className="bottomRowFlex">
      </div>
    </div>
  );
};

export default Home;
