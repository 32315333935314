import React from "react";
import Home from "./Home";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import "./cod/styles/App.css";
import ScrollToTop from 'react-router-scroll-top';
import Download from "./checkmate/Download";
import Privacy from "./checkmate/Privacy";
import ComingSoon from "./checkmate/ComingSoon";

const App = () => {
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/checkmate/download" exact component={Download} />
          <Route path="/checkmate/privacy" exact component={Privacy} />
          <Route path="/checkmate/coming-soon" exact component={ComingSoon} />
          <Route path="*" component={Home} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
};

export default App;
