import React, { memo } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import geoUrl from "./cod/files/mapNoAntarctica.json"

// const geoUrl =
// "https://raw.githubusercontent.com/johan/world.geo.json/master/countries.geo.json";

const testSVG = (
  <svg width="100%" height="100%">
    {/* <!-- Create mask that we'll use to define a slight gradient --> */}
    <mask maskUnits="userSpaceOnUse" id="fade">
      {/* <!-- Here's that slight gradient --> */}
      <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="100%">
        <stop offset="0" style="stop-color: #FFFFFF"></stop>
        <stop offset="1" style="stop-color: #000000"></stop>
      </linearGradient>
      {/* <!-- The canvas for our mask --> */}
      <rect fill="url(#gradient)" width="100%" height="100%"></rect>
    </mask>
    {/* <!-- Let's define the pattern -->
  <!-- The width and height should be double the circle radius we plan to use --> */}
    <pattern id="pattern-circles" x="0" y="0" width="40" height="40" patternUnits="userSpaceOnUse">
      {/* <!-- Now let's draw the circle -->
    <!-- We're going to define the `fill` in the CSS for flexible use --> */}
      <circle mask="url(#fade)" cx="20" cy="20" r="20"></circle>
    </pattern>
    {/* <!-- The canvas with our applied pattern --> */}
    <rect x="0" y="0" width="100%" height="100%" fill="url(#pattern-circles)"></rect>
  </svg>
)

const MapChart = ({ setTooltipContent, mapSelection, setMapSelection, toggleSwitch }) => {

  return (
    <>
      <ComposableMap data-tip="" projectionConfig={{ scale: 200 }}>
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map(geo => {
              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  onMouseEnter={() => {
                    const { name, POP_EST } = geo.properties;
                    setTooltipContent({
                      content: `${name}`
                    });
                  }}
                  onMouseLeave={() => {
                    setTooltipContent({ content: "" });
                  }}
                  onClick={() => {
                    const { name, POP_EST } = geo.properties;
                    // setMapSelection(name);
                  }}
                  style={
                    geo.properties.name === mapSelection
                      ? {
                        default: {
                          fill: "#1f8c7f",
                          outline: "none",
                        },
                        hover: {
                          fill: "#1f8c7f90",
                          outline: "none"
                        },
                        pressed: {
                          fill: "#1f8c7f",
                          outline: "none"
                        }
                      } : toggleSwitch && (geo.properties.name === "Peru" ||
                        geo.properties.name === "United States of America" ||
                        geo.properties.name === "England" ||
                        geo.properties.name === "Belize" ||
                        geo.properties.name === "Iceland" ||
                        geo.properties.name === "China" ||
                        geo.properties.name === "Italy" ||
                        geo.properties.name === "Germany" ||
                        geo.properties.name === "France" ||
                        geo.properties.name === "United Kingdom" ||
                        geo.properties.name === "Canada" ||
                        geo.properties.name === "Netherlands" ||
                        geo.properties.name === "Mexico" ||
                        geo.properties.name === "Japan" ||
                        geo.properties.name === "Denmark" ||
                        geo.properties.name === "Dominican Republic" ||
                        geo.properties.name === "Austria" ||
                        geo.properties.name === "Spain")
                        ? {
                          default: {
                            fill: "#000",
                            outline: "none",
                            transition: "0.4s",
                          },
                          hover: {
                            fill: "#00000040",
                            outline: "none",
                            transition: "0.4s",
                          },
                          pressed: {
                            fill: "#00000080",
                            outline: "none",
                            transition: "0.4s",
                          }
                        }
                        : {
                          default: {
                            fill: "#d4d4d490",
                            outline: "none",
                            transition: "0.4s",
                          },
                          hover: {
                            fill: "#00000040",
                            outline: "none",
                            transition: "0.4s",
                          },
                          pressed: {
                            fill: "#000",
                            outline: "none",
                            transition: "0.4s",
                          }
                        }
                  }
                />
              );
            })
          }
        </Geographies>
      </ComposableMap>
    </>
  );
};

export default memo(MapChart);
