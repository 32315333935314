import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import MapChart from "./MapChart";

const Map = (props) => {
  const { toggleSwitch } = props;
  const [mapSelection, setMapSelection] = useState("");
  const [content, setContent] = useState({
    content: ""
  });

  return (
    <div className="mapDiv">
      <MapChart
        mapSelection={mapSelection}
        setMapSelection={setMapSelection}
        setTooltipContent={setContent}
        toggleSwitch={toggleSwitch}
      />
      {/* NEXT

        Make a tooltip with name + images if exists
      
      */}
      <ReactTooltip>{content.content}</ReactTooltip>
    </div>
  );
};

export default Map;
