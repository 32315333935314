import React from 'react';
import './Privacy.css';

const Privacy = () => {
  return (
    <div className="privacy-container">
      <div className="privacy-content">
        <h1>Checkmate App: Privacy Policy</h1>
        <p className="last-updated">Last updated: March 15, 2025</p>

        <section>
          <h2>1. Introduction</h2>  
          <p>
            At Checkmate ("we," "our," or "us"), we take your privacy seriously. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our Checkmate: Routine Planner mobile application.
          </p>
        </section>

        <section>
          <h2>2. Information We Collect</h2>
          <h3>2.1 Information You Provide</h3>
          <ul>
            <li>Account information (email address, name, password)</li>
            <li>Profile information (profile picture, time zone)</li>
            <li>Routine and schedule data</li>
            <li>User preferences and settings</li>
          </ul>

          <h3>2.2 Automatically Collected Information</h3>
          <ul>
            <li>Device information (device type, operating system)</li>
            <li>Usage data (app features used, interaction time)</li>
            <li>Performance data (crash reports, diagnostics)</li>
          </ul>
        </section>

        <section>
          <h2>3. How We Use Your Information</h2>
          <p>We use the collected information to:</p>
          <ul>
            <li>Provide and maintain our services</li>
            <li>Personalize your experience</li>
            <li>Improve our app features and functionality</li>
            <li>Send important notifications and updates</li>
            <li>Respond to your requests and support needs</li>
            <li>Analyze usage patterns and optimize performance</li>
          </ul>
        </section>

        <section>
          <h2>4. Data Sharing and Disclosure</h2>
          <p>We may share your information with:</p>
          <ul>
            <li>Service providers (hosting, analytics, customer support)</li>
            <li>Third-party integrations (when you enable them)</li>
            <li>Legal authorities (when required by law)</li>
          </ul>
          <p>We do not sell your personal information to third parties.</p>
        </section>

        <section>
          <h2>5. Data Security</h2>
          <p>
            We implement appropriate technical and organizational measures to protect your personal information. However, no method of transmission over the internet is 100% secure, and we cannot guarantee absolute security.
          </p>
        </section>

        <section>
          <h2>6. Your Rights</h2>
          <p>You have the right to:</p>
          <ul>
            <li>Access your personal information</li>
            <li>Correct inaccurate data</li>
            <li>Request deletion of your data</li>
            <li>Opt-out of marketing communications</li>
            <li>Export your data</li>
          </ul>
        </section>

        <section>
          <h2>7. Children's Privacy</h2>
          <p>
            Our services are not intended for children under 13. We do not knowingly collect personal information from children under 13. If you become aware that a child has provided us with personal information, please contact us.
          </p>
        </section>

        <section>
          <h2>8. Changes to This Policy</h2>
          <p>
            We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date.
          </p>
        </section>

        <section>
          <h2>9. Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy or our practices, please contact us at:
          </p>
          <a href="mailto:info@getcheckmate.app" style={{ color: '#0066cc', textDecoration: 'underline' }}>
            info@getcheckmate.app
          </a>
        </section>
      </div>
    </div>
  );
};

export default Privacy;